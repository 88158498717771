// dashboard/src/utils/utils.js
import { format } from 'date-fns';


export const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'Never';

  try {
    // Handle Firestore timestamp format that comes through Cloud Functions
    if (timestamp._seconds) {
      // Convert seconds and nanoseconds to milliseconds
      const milliseconds = (timestamp._seconds * 1000) + (timestamp._nanoseconds / 1000000);
      return format(new Date(milliseconds), 'MMM d, yyyy HH:mm');
    }

    return 'Invalid Date';
  } catch (error) {
    console.log('Error formatting timestamp:', error, timestamp);
    return 'Invalid Date';
  }
};