// dashboard/src/pages/Store.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import CustomizeDialog from '../components/store/CustomizeDialog';
import { ShoppingCart } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import Cart from '../components/store/Cart';
import useCart from '../hooks/useCart';
import { formatCurrency } from '../utils/Intl'

const StorePage = () => {
  const { storeUrl } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [customizeProduct, setCustomizeProduct] = useState(null);
  const { items, isOpen, addItem, removeItem, setIsOpen } = useCart(storeUrl);

  useEffect(() => {
    const loadStore = async () => {
      try {
        const functions = getFunctions();
        const getStoreByUrl = httpsCallable(functions, 'getLocationByUrl');
        const response = await getStoreByUrl({ storeUrl });
        const { location, products } = response.data;

        setStore(location);
        // Sort products alphabetically by name
        const sortedProducts = products.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setProducts(sortedProducts);
      } catch (err) {
        setError('Store not found');
        console.error('Error loading store:', err);
      } finally {
        setLoading(false);
      }
    };

    loadStore();
  }, [storeUrl]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-t-transparent rounded-full animate-spin"
          style={{ borderColor: store?.colorPalette?.primary || '#4F46E5' }}
        />
      </div>
    );
  }

  if (error || !store) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <Alert variant="destructive">
          <AlertTitle>Store Not Found</AlertTitle>
          <AlertDescription>
            The store you're looking for doesn't exist.
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  const { publicName, colorPalette, currency } = store;

  return (
    <div style={{
      backgroundColor: colorPalette.background,
      color: colorPalette.text
    }}>
      {/* Header */}
      <header className="sticky top-0 shadow-md z-20" style={{
        backgroundColor: colorPalette.primary,
        color: '#ffffff'
      }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">{publicName}</h1>
            <button
              onClick={() => setIsOpen(true)}
              className="relative p-2 rounded-full hover:bg-white/10"
            >
              <ShoppingCart size={24} />
              {items.length > 0 && (
                <span className="absolute -top-1 -right-1 w-5 h-5 rounded-full text-xs flex items-center justify-center"
                  style={{ backgroundColor: colorPalette.accent }}>
                  {items.length}
                </span>
              )}
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {products.map((product) => (
            <div
              key={product.id}
              className="relative rounded-lg overflow-hidden shadow-lg group"
              style={{ backgroundColor: colorPalette.secondary + '10' }}
            >
              <div className="aspect-w-1 aspect-h-1 w-full">
                {product.primaryImageUrl ? (
                  <img
                    src={product.primaryImageUrl}
                    alt={product.name}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-400">No image</span>
                  </div>
                )}
              </div>

              <div className="p-4">
                <h2 className="text-lg font-semibold mb-2">{product.name}</h2>
                <p className="text-sm mb-4" style={{ color: colorPalette.textLight }}>
                  {product.description}
                </p>

                <div className="flex items-center justify-between">
                  <div className="text-lg font-bold" style={{ color: colorPalette.accent }}>
                    {formatCurrency(product.price, currency)}
                  </div>

                  <button
                    onClick={() => {
                      if (product.customizations?.length > 0) {
                        setCustomizeProduct(product);
                      } else {
                        addItem({
                          productId: product.id,
                          name: product.name,
                          imageUrl: product.primaryImageUrl,
                          basePrice: product.price,
                          totalBasePrice: product.price,
                          quantity: 1,
                          cartId: Date.now().toString()
                        });
                      }
                    }}
                    className="px-4 py-2 rounded-lg text-white font-medium"
                    style={{ backgroundColor: colorPalette.primary }}
                  >
                    Add to Cart
                  </button>
                </div>

                {product.customizations?.length > 0 && (
                  <div className="mt-2 text-sm" style={{ color: colorPalette.textLight }}>
                    Customizable with {product.customizations.length} option{product.customizations.length !== 1 ? 's' : ''}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </main>

      {/* Cart Component */}
      <Cart
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        items={items}
        onRemoveItem={removeItem}
        colorPalette={colorPalette}
        currency={currency}
        storeUrl={storeUrl}  // Add this line
      />

      {/* Customization Dialog */}
      {customizeProduct && (
        <CustomizeDialog
          product={customizeProduct}
          onAdd={addItem}
          onClose={() => setCustomizeProduct(null)}
          colorPalette={colorPalette}
          currency={currency}
        />
      )}
    </div>
  );
};

export default StorePage;